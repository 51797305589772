import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormLayout } from "../../components/Layout";
import { addMessage } from "../../state/reducers/appReducer";
import { fetchBaseOptions } from "../../utils/fetch";

const AccountActivationPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    const token = params.get("token");
    if (token !== "") {
      fetch("/api/user/activate", {
        ...fetchBaseOptions,
        method: "PATCH",
        body: JSON.stringify({ token: token }),
      }).then(async res => {
        const j: IAPIResponse = await res.json();
        dispatch(addMessage(j));
        if (res.ok) {
          navigate("/login");
        }
      });
    }
  }, []);
  return (
    <FormLayout seo={{ title: "Activate account" }}>
      <p>
        If that didn't work,{" "}
        <Link to="/account/request-activation">request a new activation link</Link>
      </p>
    </FormLayout>
  );
};

export default AccountActivationPage;
